@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */
@keyframes blow-up {
    0% {
        transform: scale(0.1);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes bg-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.blow-up {
    animation: blow-up 1s ease-out;
}

.bg-fade-in {
    animation: bg-fade-in 1s ease-out;
}

* {
    -webkit-user-drag: none;
    user-select: none;
}

.svg-button {
    filter: brightness(0) saturate(100%) invert(53%) sepia(36%) saturate(7027%) hue-rotate(202deg) brightness(99%) contrast(96%);
    transition: filter 0.150s ease-in-out;
}

a:hover .svg-button {
    filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(25%) hue-rotate(271deg) brightness(105%) contrast(100%);
}

button:hover .svg-button {
    filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(25%) hue-rotate(271deg) brightness(105%) contrast(100%);
}